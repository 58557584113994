import React, { Component } from 'react';

import { Button, Form, TextArea, Loader } from 'semantic-ui-react';
import { Ratings } from '../api';
import Emoticons from './Emoticons';
import { environment } from '../environment';

import { bugsnagClient } from '../bugsnag';

import './Survey.css';

const cdnURL = `https://cdn.app.corsicatech.com/assets/images/`;

const CSATGreat = `${cdnURL}/great_emoji.svg`;
const CSATGood = `${cdnURL}/good_emoji.svg`;
const CSATBad = `${cdnURL}/bad_emoji.svg`;


const getRating = rating => {
  let result;
  switch (rating) {
    case 'great':
      result = {
        title: '',
        body:
          "We'd love to learn more. Can you tell us what made it great?",
        logo: CSATGreat,
        placeholder: 'My experience was great because...',
      };
      break;
    case 'good':
      result = {
        title: '',
        body:
          "We'd love to learn more. Can you tell us what made it so good?",
        logo: CSATGood,
        placeholder: 'My experience was good because...',
      };
      break;
    case 'bad':
      result = {
        title: '',
        body:
          "We're sorry to hear about your experience. Can you tell us what happened and what we can do better next time?",
        logo: CSATBad,
        placeholder: 'My experience could have been better if...',
      };
      break;
    default:
      result = {
        title: '',
        body: '',
        logo: CSATGreat,
        placeholder: '',
      };
  }
  return result;
};

class Survey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      isSubmitted: false,
      ratingId: null,
      isError: false,
      errorMessage: '',
      isLoading: true,
      placeholderText: '',
      ratingFromEmoticons: null,
      consentGiven: false,
      ticketNumber: null,
    };
  }

  componentDidMount() {
    this._postRating();
  }

  _postRating = async () => {
    const { rating, clientId, ticketId, contactId } = this.props;

    if (!rating || !clientId || !ticketId || !contactId) {
      console.error('ERROR: missing key params');
    }
    let res = null;
    let err = null;

    if (environment.isLocal) {
      console.error('===LOCAL VERSION LOADED===');
      console.error('NOT ACTUALLY SUBMITTING DATA TO THE API, ONLY SIMULATED!');
      // maybe also simulate error states based on query param
      res = { body: { id: 1 } };
    } else {
      try {
        res = await Ratings.create({
          body: {
            rating: {
              account_id: clientId,
              ticket_id: ticketId,
              contact_id: contactId,
              rating: rating,
              source: 'email',
            },
          },
        });
      } catch (error) {
        err = error;
      }
    }
    if (res && res.body && res.body.id) {
      this.setState({
        ratingId: res.body.id,
        wasSuccessful: true,
        isLoading: false,
      });
    } else {
      let errorMessage = '';
      const errMsg =
        err && err.response && err.response.body && err.response.body.error;
      const ratingId =
        err && err.response && err.response.body && err.response.body.id;
      let alreadyProvided = false;
      let isActualError = false;

      switch (errMsg) {
        case 'Invalid Submission':
        case 'Unauthorized':
          errorMessage =
            'Unfortunately, there was an error submitting your response.';
          isActualError = true;
          break;
        case 'Already provided':
          alreadyProvided = true;
          break;
        case 'Rating period expired':
          errorMessage = (
            <p>
              The feedback period for that ticket has expired. If you have
              additional feedback, please contact us at{' '}
              <span>service@corsicatech.com</span>.
            </p>
          );
          break;

        default:
          errorMessage =
            'Unfortunately, there was an error submitting your response. Please try again later.';
          isActualError = true;
      }

      if (alreadyProvided) {
        let ticketResponse;
        let comment = '';
        let rating = '';
        let ticketNumber = '';

        this.setState({ isLoading: true });

        try {
          ticketResponse = await Ratings.show({ id: ratingId });
        } catch (err) {
          console.error(err);
          bugsnagClient.leaveBreadcrumb('Fetch rating failed', {
            httpErrorCode: err && err.status,
            response: err && err.response && err.response.text,
            state: this.state,
            props: this.props,
          });
          bugsnagClient.notify(err);
        }

        if (ticketResponse && ticketResponse.body) {
          comment = ticketResponse.body.comment;
          rating = ticketResponse.body.rating;
          ticketNumber = ticketResponse.body.ticket_number;
        }

        this.setState({
          value: comment || '',
          ticketNumber,
          ratingFromEmoticons: rating,
          ratingId,
          wasSuccessful: true,
          isLoading: false,
          errorMessage: '',
          isAlreadyProvided: true,
          consentGiven: true,
        });
      } else {
        this.setState({ isError: true, errorMessage, isLoading: false });
      }
      if (isActualError) {
        bugsnagClient.leaveBreadcrumb('Rating submit failed', {
          httpErrorCode: err && err.status,
          response: err && err.response && err.response.text,
          state: this.state,
          props: this.props,
        });
        bugsnagClient.notify(err);
      }
    }
  };

  handleTextChange = (e, { value }) => {
    this.setState({ value });
  };

  // updated to include the new rating from Emoticons component (if present) and also to indicate whether consent is given to use comments for marketing purposes
  handleSubmit = async () => {
    this.setState({ isLoading: true });
    const {
      value,
      ratingId,
      ratingFromEmoticons,
      consentGiven,
    } = this.state;
    const { rating, clientId, ticketId, contactId } = this.props;
    let ratingToSend;
    if (ratingFromEmoticons === null) {
      ratingToSend = rating;
    } else {
      ratingToSend = ratingFromEmoticons;
    }
    let res;
    try {
      res = await Ratings.update({
        id: ratingId,
        body: {
          rating: {
            account_id: clientId,
            ticket_id: ticketId,
            contact_id: contactId,
            comment: value,
            rating: ratingToSend,
            marketing_consent: consentGiven,
            source: 'email',
          },
        },
      });
    } catch (err) {
      console.error(err);
      bugsnagClient.leaveBreadcrumb('Rating update failed', {
        httpErrorCode: err && err.status,
        response: err && err.response && err.response.text,
        ratingToSend,
        state: this.state,
        props: this.props,
      });
      bugsnagClient.notify(err);
    }
    if (res) {
      this.setState({
        isSubmitted: true,
        wasSuccessful: true,
        isLoading: false,
      });
    } else {
      this.setState({ wasSuccessful: false, isLoading: false });
    }
  };

  updateRating = a => {
    this.setState({
      ratingFromEmoticons: a,
    });
  };

  giveConsent = () => {
    this.setState(prevState => ({
      consentGiven: !prevState.consentGiven,
    }));
  };

  render() {
    let buttonText = '';
    if (
      this.state.ratingFromEmoticons &&
      this.props.rating !== this.state.ratingFromEmoticons &&
      this.state.value.length < 1
    ) {
      buttonText = 'Update Rating';
    } else if (
      this.state.ratingFromEmoticons &&
      this.props.rating !== this.state.ratingFromEmoticons &&
      this.state.value.length >= 1
    ) {
      buttonText = 'Update Rating & Send This Feedback';
    } else if (
      this.props.rating === this.state.ratingFromEmoticons &&
      this.state.value.length < 1
    ) {
      buttonText = 'Send This Feedback';
    } else if (
      this.props.rating === this.state.ratingFromEmoticons &&
      this.state.value.length >= 1
    ) {
      buttonText = 'Send This Feedback';
    } else if (this.state.ratingFromEmoticons === null) {
      buttonText = 'Send This Feedback';
    }

    const {
      value,
      isSubmitted,
      wasSuccessful,
      ratingFromEmoticons,
      errorMessage,
      isLoading,
      isAlreadyProvided,
      ticketNumber,
    } = this.state;
    const { rating, ticketId } = this.props;

    const alreadyProvidedMessage = (
      <>
        It looks like you have already submitted feedback for ticket{' '}
        <strong>{ticketNumber}</strong>.You can update your rating below.
      </>
    );
    let selectedRating;
    if (ratingFromEmoticons === null) {
      selectedRating = getRating(rating);
    } else if (
      ratingFromEmoticons === 'great' ||
      ratingFromEmoticons === 'good' ||
      ratingFromEmoticons === 'bad'
    ) {
      selectedRating = getRating(ratingFromEmoticons);
    }

    const { body, logo, placeholder } = selectedRating || {};

    let actualRating;
    if (ratingFromEmoticons === null) {
      actualRating = rating;
    } else {
      actualRating = ratingFromEmoticons;
    }

    let buttonStatus = true;
    if (this.state.value.length > 0 && actualRating !== null) {
      buttonStatus = false;
    } else if (ratingFromEmoticons && ratingFromEmoticons !== rating) {
      buttonStatus = false;
    }

    return isLoading ? (
      <section className="survey">
        <Loader active />
      </section>
    ) : (
      <>
        {!!environment.isLocal && (
          <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
            <Button onClick={() => this.setState({ isAlreadyProvided: false })}>
              Set Not Provided
            </Button>
            <Button onClick={() => this.setState({ isAlreadyProvided: true })}>
              Set Provided
            </Button>
          </div>
        )}
        <section className={`survey ${errorMessage ? 'error-display' : ''}`}>
          {!!wasSuccessful && (
            <img src={logo} className="csat-emoji-img" alt="" />
          )}

          {!isSubmitted && wasSuccessful ? (
            <section className="content">
              <h2 className="feedback-title">Thanks for your feedback!</h2>
              {isAlreadyProvided && (
                <p className="body">{alreadyProvidedMessage}</p>
              )}
              {!isAlreadyProvided && (
                <div className="body">
                  <p>Your rating has been successfully recorded for <b>Ticket {ticketId}.</b><br/>
                  {body}</p>
                </div>
              )}
              <div className="form-field">
                <Form>
                  <TextArea
                    rows="3"
                    placeholder={placeholder}
                    value={value}
                    onChange={this.handleTextChange}
                    style={{
                      display: 'block',
                      border: '1px solid rgba(95,105,114,0.32)',
                    }}
                  />
                </Form>
              </div>
              {this.state.value.length > 0 &&
              actualRating === 'great' &&
              !isAlreadyProvided ? (
                <div>
                  <input type="checkbox" onClick={this.giveConsent} />
                  <p className="changeRating">
                    {' '}
                    I give Corsica Technologies permission to use my name and
                    comment in public marketing materials.
                  </p>
                </div>
              ) : null}
              <div className="submit-section">
                  <div>
                    <Button
                      className="submit-btn"
                      disabled={buttonStatus}
                      onClick={this.handleSubmit}
                      primary>
                      {isAlreadyProvided ? 'Update Feedback' : buttonText}
                    </Button>
                  </div>
                  <EmoticonPicker
                      isAlreadyProvided={isAlreadyProvided}
                      rating={this.props.rating}
                      updateRating={this.updateRating}
                  />
              </div>
            </section>
          ) : wasSuccessful ? (
            <section className="content success">
              <p>Thank you for your feedback!</p>
            </section>
          ) : null}
          {errorMessage ? (
            <div className="errorMessage">{errorMessage}</div>
          ) : null}
        </section>
      </>
    );
  }
}

export { Survey };

const EmoticonPicker = ({
  isAlreadyProvided,
  rating,
  updateRating,
}) => (
  <div style={{display: 'flex', justifyContent: 'space-between'}}>
    {!isAlreadyProvided && (
      <p className="changeRating" style={{display: 'flex', alignItems: 'center'}}>Change your rating?</p>
    )}
    <Emoticons
      embolden={true}
      primary={rating}
      updateSurveyRating={updateRating}
    />
  </div>
);
