const LOCALHOST_FALLBACK = 'https://feedback.dev.corsicatech.com';

const envStages = {
  local: {
    name: 'local',
    apiBase: process.env.REACT_APP_API_BASE,
    production: false,
    token: 'vfLOjGzeBwgORf2FiJ7Z4Wnpk4YZxePv'
  },
  development: {
    name: 'development',
    apiBase: process.env.REACT_APP_API_BASE || LOCALHOST_FALLBACK,
    production: false,
    token: 'vfLOjGzeBwgORf2FiJ7Z4Wnpk4YZxePv'
  },
  production: {
    name: 'production',
    apiBase: process.env.REACT_APP_API_BASE,
    production: true,
    token: 'pxEpFu0BRE1V90Uh9ssTVaLi7qUCU5kR'
  }
};

const checkEnv = env => process.env.REACT_APP_ENV === env.name;

export const environment = {
  env: process.env.REACT_APP_ENV,
  isLocal: checkEnv(envStages.local),
  isProduction: checkEnv(envStages.production),
  isDevelopment: checkEnv(envStages.development),
  get current() {
    return envStages[process.env.REACT_APP_ENV] || envStages.development;
  },
};
