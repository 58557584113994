import superagent from 'superagent';

import { environment } from './environment';

const API_BASE = environment.current.apiBase;
const TOKEN = environment.current.token;

const version = '/api/v3';

const request = {
  post: (url, body) =>
    superagent
      .post(`${API_BASE}${version}${url}`, body)
      .set('Authorization', `Bearer ${TOKEN}`),
  put: (url, body) =>
    superagent
      .put(`${API_BASE}${version}${url}`, body)
      .set('Authorization', `Bearer ${TOKEN}`),
  get: url =>
    superagent
      .get(`${API_BASE}${version}${url}`)
      .set('Authorization', `Bearer ${TOKEN}`),
};

const Tickets = {
  all: () => request.get('/tickets'),
  create: ({ body }) => request.post('/tickets', body),
  update: ({ id, body }) => request.put(`/tickets/${id}`, body),
  show: ({ id }) => request.get(`/tickets/${id}`),
};
const Ratings = {
  all: () => request.get('/ratings'),
  create: ({ body }) => request.post('/ratings', body),
  update: ({ id, body }) => request.put(`/ratings/${id}`, body),
  show: ({ id }) => request.get(`/ratings/${id}`),
};

export { Tickets, Ratings };
