const qpParse = search => {
  let params = {};
  if (search && search.length > 1) {
    let pairs = search.split('?');
    pairs = pairs.length > 1 ? pairs[1].split('&') : pairs[0].split('&');
    pairs.map(pair => {
      pair = pair.split('=');
      if (pair.length === 2) {
        return (params[pair[0]] = decodeURIComponent(pair[1]));
      } else {
        return (params[pair[0]] = '');
      }
    });
  }

  return params;
};

const qpGetAll = () => {
  const qs = window.location.search;
  return qpParse(qs);
};

const qpGet = param => qpGetAll()[param];

export { qpParse, qpGet, qpGetAll };
