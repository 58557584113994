import React from 'react';

import './Emoticons.css';

const cdnURL = `https://cdn.app.corsicatech.com/assets/images/`;

const CSATGreat = `${cdnURL}/great_emoji.svg`;
const CSATGood = `${cdnURL}/good_emoji.svg`;
const CSATBad = `${cdnURL}/bad_emoji.svg`;

const CSATGreatColor = `${cdnURL}/great_emoji.svg`;
const CSATBadColor = `${cdnURL}/bad_emoji.svg`;
const CSATGoodColor = `${cdnURL}/good_emoji.svg`;


const BWCSATGreat = `${cdnURL}/icon_csat_great_bwoutline.svg`;
const BWCSATGood = `${cdnURL}/icon_csat_good_bwoutline.svg`;
const BWCSATBad = `${cdnURL}/icon_csat_bad_bwoutline.svg`;

class Emoticons extends React.Component {
  state = {
    rating: this.props.primary,
  };

  updateRating = (e) => {
    // first update the local UI
    e.persist();
    this.setState(() => ({
      rating: e.target.id,
    }));
    // then update Survey.js
    this.props.updateSurveyRating(e.target.id);
  };

  render() {
    const { embolden } = this.props;
    return (
      <div className="emoticons">
        <img
          alt="Great"
          src={
            this.state.rating === 'great'
              ? BWCSATGreat
              : embolden
              ? CSATGreatColor
              : CSATGreat
          }
          onClick={this.updateRating}
          id="great"
        />
        <img
          alt="Good"
          src={
            this.state.rating === 'good'
              ? BWCSATGood
              : embolden
              ? CSATGoodColor
              : CSATGood
          }
          onClick={this.updateRating}
          id="good"
        />
        <img
          alt="Bad"
          src={
            this.state.rating === 'bad' ? BWCSATBad : embolden ? CSATBadColor : CSATBad
          }
          onClick={this.updateRating}
          id="bad"
        />
      </div>
    );
  }
}

export default Emoticons;
