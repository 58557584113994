import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { Survey } from './components/Survey';
import { qpGetAll } from './utils';

import logo from './img/Dark-CorsicaTech_Logo_Color.svg';

import './App.css';
import 'semantic-ui-css/semantic.min.css';
import './styles/semantic.css';

function App() {
  const qps = qpGetAll();
  const { rating, client_id, ticket_id, contact_id } = qps || {};
  return (
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      <Survey
        rating={rating}
        clientId={client_id}
        ticketId={ticket_id}
        contactId={contact_id}
      />
    </div>
  );
}

function RoutedApp() {
  return (
    <Router>
      <Switch>
        <Route path="/tickets" component={App} />
        <Redirect to="/tickets" />
      </Switch>
    </Router>
  );
}

export default RoutedApp;
